import { Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class ImageWidget extends BaseWidget<{ image: string }> {
  static readonly id = 'image-widget';
  static readonly publicFriendly = true;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { image: string }
  ) {
    return new ImageWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { image: string }
  ) {
    super(injector, ImageWidget.id, ImageWidget.publicFriendly, metaLinkId, x, y, rows, cols, data, false);
    this.resolveComponent(async () => {
      const module = await import('./image-widget.component');
      return Object.values(module)[0];
    });
  }
}
