import { Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class TextWidget extends BaseWidget<{ text: string }> {
  static readonly id = 'text-widget';
  static readonly publicFriendly = true;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { text: string }
  ) {
    return new TextWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { text: string }
  ) {
    super(injector, TextWidget.id, TextWidget.publicFriendly, metaLinkId, x, y, rows, cols, data, false);
    this.resolveComponent(async () => {
      const module = await import('./text-widget.component');
      return Object.values(module)[0];
    });
  }
}
